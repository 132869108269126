var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    attrs: {
      "id": "topnav-login"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "border-bottom-nav",
    attrs: {
      "id": "navigation"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('ul', {
    directives: [{
      name: "scroll-spy-active",
      rawName: "v-scroll-spy-active",
      value: {
        selector: 'li.has-submenu'
      },
      expression: "{ selector: 'li.has-submenu' }"
    }],
    staticClass: "navigation-menu mb-0"
  }, [_c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "https://awarisgroup.com/"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-left mr-1"
  }), _vm._v(_vm._s(_vm.$t("navbar.homepage")))])])])]), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "text-white text-right pr-4 btn-hai"
  }, [_c('p', {
    staticClass: "p-lang"
  }, [_vm._v(" " + _vm._s(_vm.$t("lang")) + " "), _c('span', {
    staticClass: "lang-bg ml-2"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('ms');
      }
    }
  }, [_vm._v(" BM ")]), _vm._v(" | "), _c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('en');
      }
    }
  }, [_vm._v(" ENG ")])])])])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container",
    staticStyle: {
      "margin-top": "100px"
    }
  }, [_c('div', {
    staticClass: "bg-overlay bg-overlay-white bg-overlay-img"
  }), _c('div', {
    staticClass: "row justify-content-center",
    staticStyle: {
      "margin-bottom": "50px"
    }
  }, [_c('div', {
    staticClass: "card verification-page bg-white shadow rounded-login border-0"
  }, [_c('div', {
    staticClass: "card-body-login"
  }, [_c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('div', {
    staticClass: "card-title m-0"
  }, [_c('h5', {
    staticClass: "card-label m-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("pdpa")) + " ")])])]), _c('div', {
    staticClass: "card-body"
  }, [_c('p', {
    staticClass: "text-justify"
  }, [_vm._v(" " + _vm._s(_vm.$t("pdpa-wasiat-details")) + " ")]), _c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.acknowledged_pdpa,
      expression: "form.acknowledged_pdpa"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "exampleCheck1",
      "value": "1",
      "disabled": _vm.form.acknowledged_pdpa == 1 && _vm.wasiat.status >= 1
    },
    domProps: {
      "checked": Array.isArray(_vm.form.acknowledged_pdpa) ? _vm._i(_vm.form.acknowledged_pdpa, "1") > -1 : _vm.form.acknowledged_pdpa
    },
    on: {
      "click": function ($event) {
        _vm.isShowing_pdpa ^= true;
      },
      "change": function ($event) {
        var $$a = _vm.form.acknowledged_pdpa,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "acknowledged_pdpa", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "acknowledged_pdpa", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "acknowledged_pdpa", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "label-agree-term",
    attrs: {
      "for": "exampleCheck1"
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t("understand-pdpa")) + " ")])])])])]), _c('br'), _c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('div', {
    staticClass: "card-title m-0"
  }, [_c('h5', {
    staticClass: "card-label m-0"
  }, [_vm._v(_vm._s(_vm.$t("will-confirmation")))])])]), _c('div', {
    staticClass: "card-body"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("i")) + ", "), _c('strong', {
    staticStyle: {
      "text-decoration": "underline"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.name)
    }
  }), _vm._v(", " + _vm._s(_vm.$t("mykad")) + " "), _c('strong', {
    staticStyle: {
      "text-decoration": "underline"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.ic_number)
    }
  })]), _c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.acknowledged_confirmation,
      expression: "form.acknowledged_confirmation"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "exampleCheck2",
      "value": "1",
      "disabled": _vm.form.acknowledged_confirmation == 1 && _vm.wasiat.status >= 1
    },
    domProps: {
      "checked": Array.isArray(_vm.form.acknowledged_confirmation) ? _vm._i(_vm.form.acknowledged_confirmation, "1") > -1 : _vm.form.acknowledged_confirmation
    },
    on: {
      "click": function ($event) {
        _vm.isShowing_confirmation ^= true;
      },
      "change": function ($event) {
        var $$a = _vm.form.acknowledged_confirmation,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "acknowledged_confirmation", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "acknowledged_confirmation", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "acknowledged_confirmation", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "label-agree-term",
    attrs: {
      "for": "exampleCheck2"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t("agree-and-understand")) + ":")])])]), _c('p', [_c('ol', [_vm.wasiat.price == 1200 ? _c('li', [_vm._v(" " + _vm._s(_vm.$t("appoint-awaris-executor-trustee")) + ": ")]) : _vm._e(), _vm.wasiat.price == 1200 ? _c('ul', [_c('li', [_vm._v(_vm._s(_vm.$t("1m-below")))]), _c('li', [_vm._v(_vm._s(_vm.$t("1m-to-2m")))]), _c('li', [_vm._v(_vm._s(_vm.$t("above-2m")))])]) : _vm._e(), _vm.wasiat.price != 1200 ? _c('li', [_vm._v(" " + _vm._s(_vm.$t("appoint-executor-trustee")) + " ")]) : _vm._e(), _c('li', [_vm._v(" " + _vm._s(_vm.$t("revoke-will")) + " ")])])]), _vm.wasiatKonvensional ? _c('p', [_vm._v(" 3. " + _vm._s(_vm.$t("equal-shares")) + " ")]) : _vm._e(), _vm.signature ? _c('img', {
    staticClass: "mt-3",
    staticStyle: {
      "width": "130px"
    },
    attrs: {
      "src": _vm.signature.public_path,
      "alt": "",
      "srcset": ""
    }
  }) : _vm._e(), _c('div', [_vm._v(" [ "), _c('strong', {
    domProps: {
      "innerHTML": _vm._s(_vm.name)
    }
  }), _vm._v("] ")])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowing_pdpa && _vm.isShowing_confirmation,
      expression: "isShowing_pdpa && isShowing_confirmation"
    }],
    staticClass: "row mt-3"
  }, [!_vm.signature ? _c('div', {
    staticClass: "col-md-12 text-center"
  }, [_c('div', {
    staticClass: "card",
    staticStyle: {
      "width": "500px",
      "display": "inline-block"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative",
      "height": "300px",
      "width": "100%"
    }
  }, [_c('VueSignaturePad', {
    ref: "signaturePad",
    staticStyle: {
      "position": "absolute",
      "z-index": "1"
    },
    attrs: {
      "height": "300px",
      "width": "500px",
      "options": {
        onBegin: function () {
          _vm.$refs.signaturePad.resizeCanvas();
        }
      }
    }
  }), _c('label', {
    staticStyle: {
      "z-index": "0",
      "position": "absolute",
      "right": "0px",
      "left": "0px",
      "top": "45%",
      "color": "#b6b6b4"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign-here")) + " ")]), _c('a', {
    staticClass: "btn btn-primary",
    staticStyle: {
      "position": "absolute",
      "right": "5px",
      "top": "5px",
      "z-index": "2"
    },
    on: {
      "click": _vm.undo
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/rubber.svg",
      "height": "20",
      "alt": ""
    }
  })])], 1)])]) : _vm._e()]), _c('br')]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-center mt-4 mt-sm-0"
  }, [_c('div', {
    staticClass: "mt-md-4 mt-3 mt-sm-0"
  }, [_vm.wasiat && _vm.wasiat.status != 2 ? _c('button', {
    staticClass: "btn btn-primary mr-2",
    on: {
      "click": _vm.submitform
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("done")) + " ")]) : _vm._e()])])])])])])])]), _c('footer', {
    staticClass: "footer-login footer-bar"
  }, [_c('div', {
    staticClass: "container text-center"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 mb-3"
  }, [_c('div', {
    staticClass: "text-sm-center"
  }, [_c('p', {
    staticClass: "mb-3"
  }, [_c('strong', [_vm._v("©2021 AWARIS. " + _vm._s(_vm.$t("copyright")) + ".")]), _c('br'), _vm._v(" " + _vm._s(_vm.$t("poweredby")) + " "), _c('strong', [_vm._v("PROOFFICE")])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }