<script>
import ApiPublicService from "@/services/api-public.service";
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  data() {
    return {
      wasiat: {},
      pdpa: 0,
      confirmation: 0,
      ic_number: null,
      name: null,
      wasiatKonvensional: false,
      isShowing_pdpa: false,
      isShowing_confirmation: false,
      signature: null,
      form: {
        acknowledged_pdpa: 0,
        acknowledged_confirmation: 0,
      },
      is_independent_completed: 0,
    };
  },
  components: {
    PulseLoader,
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    back() {
      this.$router.push({
        name: "wasiat.list",
        query: { wasiat_id: this.wasiatId },
      });
    },
    async submitform() {
      if (this.pdpa && this.confirmation) {
        this.next();
      }
      if (!this.form.acknowledged_pdpa) {
        this.$swal.fire({
          icon: "error",
          html: this.$t("agree-personal-data-protection-act"),
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: this.$t("close"),
        });
        return;
      } else if (!this.form.acknowledged_confirmation) {
        this.$swal.fire({
          icon: "error",
          html: this.$t("pls-agree-will-confirmation"),
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: this.$t("close"),
        });
        return;
      }
      var updateWasiat = await this.updateWasiat();
      if (!updateWasiat) {
        return;
      }
      var result;
      if (this.signature == null) {
        result = await this.saveSignature();
        if (!result) {
          return;
        }
        this.$router.push({
          name: "verification.success",
          params: { wasiat_id: this.wasiatId },
        });
      }
    },
    async saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log("Signature data empty: ", isEmpty);
      if (!isEmpty) {
        return await ApiPublicService.post(
          `/wasiat/${this.wasiatId}/signature`,
          {
            is_base64: true,
            document: data,
          }
        )
          .then((response) => {
            return true;
          })
          .catch((error) => {
            this.submit = false;
            return false;
          });
      } else {
        this.$notify({
          group: "foo",
          title: this.$t("pls-sign-before-submit"),
          type: "error",
        });
        this.submit = false;
        return false;
      }
    },
    async updateWasiat() {
      // this.form._method = "PATCH";
      return await ApiPublicService.post(`/wasiat/${this.wasiatId}`, {
        _method: "PATCH",
        acknowledged_pdpa: 1,
        acknowledged_confirmation: 1,
        is_independent_completed: 1, //agent receive email
      })
        .then((response) => {
          console.log(
            "is_independent_completed",
            this.is_independent_completed
          );
          return true;
        })
        .catch((error) => {
          this.submit = false;
          return false;
        });
    },
    next() {
      this.$router.push({
        name: "verification.success",
        params: { wasiat_id: this.wasiatId },
      });
    },
    getWasiat() {
      ApiPublicService.get(`/wasiat/${this.wasiatId}`).then((response) => {
        var wasiat = response.data.wasiat;
        if (wasiat) {
          this.wasiat = wasiat;
          this.name = wasiat.user.name;
          this.ic_number = wasiat.user.ic_number;
          this.pdpa = wasiat.acknowledged_pdpa;
          this.confirmation = wasiat.acknowledged_confirmation;

          this.form.acknowledged_pdpa = wasiat.acknowledged_pdpa;
          this.form.acknowledged_confirmation =
            wasiat.acknowledged_confirmation;
          this.is_independent_completed = wasiat.is_independent_completed;
          if (wasiat.wasiat_type == 2) {
            this.wasiatKonvensional = true;
          }
          console.log("wasiat", wasiat);
        }
      });
    },
    getSignature() {
      ApiPublicService.get(
        `/wasiat/${this.wasiatId}/signature?stream=false`
      ).then((response) => {
        console.log(response.data);
        this.signature = response.data.signature;
      });
    },
  },

  mounted() {
    if (this.wasiatId) {
      this.getWasiat();
      this.getSignature();
    }
  },
  computed: {
    ...mapGetters(["userAccess", "userRole", "currentUser"]),
    wasiatId() {
      return this.$route.params.wasiat_id;
    },
    userAccessCheck() {
      return this.userAccess;
    },
    pengesahan() {
      return this.$route.query.pengesahan;
    },
  },
};
</script>

<template>
  <div>
    <header id="topnav-login">
      <div class="container">
        <div id="navigation" class="border-bottom-nav">
          <div class="row">
            <div class="col-lg-6">
              <ul
                class="navigation-menu mb-0"
                v-scroll-spy-active="{ selector: 'li.has-submenu' }"
              >
                <li class="has-submenu">
                  <a href="https://awarisgroup.com/"
                    ><i class="mdi mdi-chevron-left mr-1"></i
                    >{{ $t("navbar.homepage") }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <div class="text-white text-right pr-4 btn-hai">
                <p class="p-lang">
                  {{ $t("lang") }} &nbsp;
                  <span class="lang-bg ml-2">
                    <span @click="changeLocale('ms')" style="cursor: pointer">
                      BM
                    </span>
                    |
                    <span @click="changeLocale('en')" style="cursor: pointer">
                      ENG
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <section class="section">
      <div class="container" style="margin-top: 100px">
        <div class="bg-overlay bg-overlay-white bg-overlay-img"></div>
        <div class="row justify-content-center" style="margin-bottom: 50px">
          <div
            class="card verification-page bg-white shadow rounded-login border-0"
          >
            <div class="card-body-login">
              <form class="login-form">
                <div class="card card-custom gutter-b">
                  <div class="card-header">
                    <div class="card-title m-0">
                      <h5 class="card-label m-0">
                        {{ $t("pdpa") }}
                      </h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <p class="text-justify">
                      {{ $t("pdpa-wasiat-details") }}
                    </p>
                    <div class="form-check">
                      <input
                        @click="isShowing_pdpa ^= true"
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        value="1"
                        :disabled="
                          form.acknowledged_pdpa == 1 && wasiat.status >= 1
                        "
                        v-model="form.acknowledged_pdpa"
                      />
                      <label class="label-agree-term" for="exampleCheck1">
                        <strong>
                          {{ $t("understand-pdpa") }}
                        </strong>
                      </label>
                    </div>
                  </div>
                </div>
                <br />
                <div class="card card-custom gutter-b">
                  <div class="card-header">
                    <div class="card-title m-0">
                      <h5 class="card-label m-0">{{ $t("will-confirmation") }}</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <p>
                      {{ $t("i") }},
                      <strong
                        style="text-decoration: underline"
                        v-html="name"
                      ></strong
                      >, {{ $t("mykad") }}
                      <strong
                        style="text-decoration: underline"
                        v-html="ic_number"
                      ></strong>
                    </p>

                    <div class="form-check">
                      <input
                        @click="isShowing_confirmation ^= true"
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck2"
                        value="1"
                        :disabled="
                          form.acknowledged_confirmation == 1 &&
                          wasiat.status >= 1
                        "
                        v-model="form.acknowledged_confirmation"
                      />
                      <label class="label-agree-term" for="exampleCheck2">
                        <strong>{{ $t("agree-and-understand") }}:</strong>
                      </label>
                    </div>
                    <p>
                      <ol>
                        <li v-if="wasiat.price == 1200">
                          {{ $t("appoint-awaris-executor-trustee") }}:
                        </li>
                        <ul v-if="wasiat.price == 1200">
                          <li>{{ $t("1m-below") }}</li>
                          <li>{{ $t("1m-to-2m") }}</li>
                          <li>{{ $t("above-2m") }}</li>
                        </ul>
                        <li v-if="wasiat.price != 1200"> 
                          {{ $t("appoint-executor-trustee") }}
                        </li>
                        <li>
                          {{ $t("revoke-will") }}
                        </li>
                      </ol>
                    </p>
                    <p v-if="wasiatKonvensional">
                      3. {{ $t("equal-shares") }}
                    </p>
                    <img
                      :src="signature.public_path"
                      alt
                      srcset
                      v-if="signature"
                      style="width: 130px"
                      class="mt-3"
                    />
                    <div>
                      [
                      <strong v-html="name"></strong>]
                    </div>
                  </div>
                </div>
                <div
                  class="row mt-3"
                  v-show="isShowing_pdpa && isShowing_confirmation"
                >
                  <div class="col-md-12 text-center" v-if="!signature">
                    <div
                      class="card"
                      style="width: 500px; display: inline-block"
                    >
                      <div
                        style="position: relative; height: 300px; width: 100%"
                      >
                        <VueSignaturePad
                          style="position: absolute; z-index: 1"
                          height="300px"
                          width="500px"
                          ref="signaturePad"
                          :options="{
                            onBegin: () => {
                              $refs.signaturePad.resizeCanvas();
                            },
                          }"
                        />
                        <label
                          style="
                            z-index: 0;
                            position: absolute;
                            right: 0px;
                            left: 0px;
                            top: 45%;
                            color: #b6b6b4;
                          "
                        >
                          {{ $t("sign-here") }}
                        </label>
                        <a
                          class="btn btn-primary"
                          style="
                            position: absolute;
                            right: 5px;
                            top: 5px;
                            z-index: 2;
                          "
                          @click="undo"
                        >
                          <img src="/images/icon/rubber.svg" height="20" alt />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </form>
              <div class="row">
                <div class="col-md-12 text-center mt-4 mt-sm-0">
                  <div class="mt-md-4 mt-3 mt-sm-0">
                    <button
                      @click="submitform"
                      v-if="wasiat && wasiat.status != 2"
                      class="btn btn-primary mr-2"
                    >
                      {{ $t("done") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer-login footer-bar">
      <div class="container text-center">
        <div class="row">
          <div class="col-sm-12 mb-3">
            <div class="text-sm-center">
              <p class="mb-3">
                <strong>©2021 AWARIS. {{ $t("copyright") }}.</strong><br />
                {{ $t("poweredby") }} <strong>PROOFFICE</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
